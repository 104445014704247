//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import {
  sysFileInfoDelete,
  sysFileInfoDownload,
  sysFileInfoPage,
  sysFileInfoUpload,
} from '@/api/modular/system/fileManage'
import { Ellipsis, STable, XCard } from '@/components'
import detailForm from './detailForm'
import previewForm from './previewForm'
export default {
  components: {
    XCard,
    STable,
    Ellipsis,
    detailForm,
    previewForm,
  },
  data() {
    return {
      // 高級搜索 展開/關閉
      advanced: false,
      // 查詢參數
      queryParam: {},
      // 表頭
      columns: [
        {
          title: '存儲位置',
          dataIndex: 'fileLocation',
          scopedSlots: { customRender: 'fileLocation' },
        },
        {
          title: '文件倉庫',
          dataIndex: 'fileBucket',
        },
        {
          title: '文件名稱',
          dataIndex: 'fileOriginName',
          scopedSlots: { customRender: 'fileOriginName' },
        },
        {
          title: '文件後綴',
          dataIndex: 'fileSuffix',
          scopedSlots: { customRender: 'fileSuffix' },
        },
        {
          title: '文件大小',
          dataIndex: 'fileSizeInfo',
        },
        {
          title: '唯一標識id',
          dataIndex: 'fileObjectName',
          scopedSlots: { customRender: 'fileObjectName' },
        },
      ],
      // 加載數據方法 必須為 Promise 對象
      loadData: (parameter) => {
        return sysFileInfoPage(Object.assign(parameter, this.queryParam)).then((res) => {
          return res.data
        })
      },
      cardLoading: false,
      fileLocationDictTypeDropDown: [],
      selectedRowKeys: [],
      selectedRows: [],
    }
  },
  created() {
    this.sysDictTypeDropDown()
    if (this.hasPerm('sysPos:edit') || this.hasPerm('sysPos:delete')) {
      this.columns.push({
        title: '操作',
        width: '200px',
        dataIndex: 'action',
        scopedSlots: { customRender: 'action' },
      })
    }
  },
  methods: {
    /**
     * 預覽文件（微軟插件）
     */
    previewMicrosoft(record) {
      window.open(
        'https://view.officeapps.live.com/op/view.aspx?src=' +
          process.env.VUE_APP_API_BASE_URL +
          '/sysFileInfo/download?id=' +
          record.id
      )
    },
    /**
     * 獲取字典數據
     */
    sysDictTypeDropDown() {
      this.fileLocationDictTypeDropDown = this.$options.filters['dictData']('file_storage_location')
    },
    /**
     * 下載文件（所有文件）
     */
    sysFileInfoDownload(record) {
      this.cardLoading = true
      sysFileInfoDownload({ id: record.id })
        .then((res) => {
          this.cardLoading = false
          this.downloadfile(res)
          // eslint-disable-next-line handle-callback-err
        })
        .catch((err) => {
          this.cardLoading = false
          this.$message.error('下載錯誤：獲取文件流錯誤')
        })
    },
    downloadfile(res) {
      var blob = new Blob([res.data], { type: 'application/octet-stream;charset=UTF-8' })
      var contentDisposition = res.headers['content-disposition']
      var patt = new RegExp('filename=([^;]+\\.[^\\.;]+);*')
      var result = patt.exec(contentDisposition)
      var filename = result[1]
      var downloadElement = document.createElement('a')
      var href = window.URL.createObjectURL(blob) // 創建下載的鏈接
      var reg = /^["](.*)["]$/g
      downloadElement.style.display = 'none'
      downloadElement.href = href
      downloadElement.download = decodeURI(filename.replace(reg, '$1')) // 下載後文件名
      document.body.appendChild(downloadElement)
      downloadElement.click() // 點擊下載
      document.body.removeChild(downloadElement) // 下載完成移除元素
      window.URL.revokeObjectURL(href)
    },
    sysFileInfoDelete(record) {
      sysFileInfoDelete(record)
        .then((res) => {
          if (res.success) {
            this.$message.success('刪除成功')
            this.$refs.table.refresh()
          } else {
            this.$message.error('刪除失敗：' + res.message)
          }
        })
        .catch((err) => {
          this.$message.error('刪除錯誤：' + err.message)
        })
    },
    toggleAdvanced() {
      this.advanced = !this.advanced
    },
    /**
     * 上傳文件
     */
    customRequest(data) {
      const formData = new FormData()
      formData.append('file', data.file)
      sysFileInfoUpload(formData).then((res) => {
        if (res.success) {
          this.$message.success('上傳成功')
          this.$refs.table.refresh()
        } else {
          this.$message.error('上傳失敗：' + res.message)
        }
      })
    },
    handleOk() {
      this.$refs.table.refresh()
    },
    onSelectChange(selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys
      this.selectedRows = selectedRows
    },
  },
}
