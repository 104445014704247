//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { sysFileInfoPreview } from '@/api/modular/system/fileManage'
export default {
  data () {
    return {
      visible: false,
      src: '',
      divLoading: false
    }
  },
  methods: {
    /**
     * 初始化
     */
    preview (record) {
      this.visible = true
      this.divLoading = true
      this.sysFileInfoPreview(record)
    },
    /**
     * 獲取圖片並轉為鏈接
     */
    sysFileInfoPreview (record) {
      sysFileInfoPreview({ id: record.id }).then((res) => {
        this.divLoading = false
        this.downloadfile(res)
      }).catch((err) => {
        this.divLoading = false
        this.$message.error('預覽錯誤：' + err.message)
      })
    },
    /**
     * 轉圖片類型
     */
    downloadfile (res) {
      const blob = new Blob([res])
      this.src = window.URL.createObjectURL(blob)
    },
    handleCancel () {
      this.src = ''
      this.visible = false
    }
  }
}
